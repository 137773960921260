import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { window } from "browser-monads"
import Layout from "../components/layout"
import Navbar from "../components/navbar"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import SEO from "../components/seo"
import "../components/home/home.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Badge from "react-bootstrap/Badge"
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"

import "bootstrap/dist/css/bootstrap.min.css"

const Telis = props => {
  const blogContent = props.data.allContentfulBlog
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? "/category/telis"
      : `/category/telis/${currentPage - 1}`
  const nextPage = `/category/telis/${currentPage + 1}`

  return (
    <Layout>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
      <SEO
        title="Блог - о Телис"
        keywords={["Телис Блог", "Блог о Телис", "Блог о компании Телис"]}
      />

      <Navbar />
      <header className="blog-header">
        <h1 className="blog-section-main-title font-weight-bold text-center mb-0">
          Блог
        </h1>
        <div className="archive-section">
          <div className="archive-nav">
            <ButtonGroup size="sm" className="mx-auto">
              <Button
                onClick={() => navigate(`/blog#blog-archive`)}
                variant="link"
                className={
                  window.location.href.indexOf("/blog") > 0
                    ? "archive-nav-link selected"
                    : "archive-nav-link"
                }
              >
                Все
              </Button>
              <Button
                onClick={() => navigate(`/category/telis#blog-archive`)}
                variant="link"
                className={
                  window.location.href.indexOf("category/telis") > 0
                    ? "archive-nav-link selected"
                    : "archive-nav-link"
                }
              >
                Телис
              </Button>
              <Button
                onClick={() => navigate(`/category/companies#blog-archive`)}
                variant="link"
                className={
                  window.location.href.indexOf("category/companies") > 0
                    ? "archive-nav-link selected"
                    : "archive-nav-link"
                }
              >
                Компании
              </Button>
              <Button
                onClick={() => navigate(`/category/operators#blog-archive`)}
                variant="link"
                className={
                  window.location.href.indexOf("category/operators") > 0
                    ? "archive-nav-link selected"
                    : "archive-nav-link"
                }
              >
                Связь
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </header>

      <Container fluid id="blog-archive">
        {blogContent.edges.map(edge => (
          <Row
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="600"
            key={edge.node.id}
            className="hoverable"
            onClick={() => navigate(`/blog/${edge.node.slug}`)}
          >
            <Col lg={4} xl={3} className="my-4 align-self-center">
              <Image
                alt=""
                src={edge.node.featuredImage.fluid.src}
                fluid
                className="w-100"
              />
            </Col>
            <Col lg={8} xl={9} className="my-4 align-self-center">
              <h4>
                {edge.node.date}{" "}
                {edge.node.category.map(category => (
                  <Badge className="ml-2 badge-telis">
                    <p className="mb-0">{category.title}</p>
                  </Badge>
                ))}
              </h4>
              <h3 className="mb-3 blog-post-title">
                <strong>{edge.node.title}</strong>
              </h3>

              <Button
                variant="outline-light"
                onClick={() => navigate(`/blog/${edge.node.slug}`)}
              >
                Читать дальше
              </Button>
            </Col>
          </Row>
        ))}
      </Container>

      <div className="pagination d-flex justify-content-between flex-row">
        {!isFirst && (
          <Link to={prevPage} rel="prev" className="pr-2 mt-3">
            <FontAwesomeIcon className="mr-2 fa-lg" icon={faArrowLeft} /> Назад
          </Link>
        )}

        {!isLast && (
          <Link to={nextPage} rel="next" className="pl-2 mt-3">
            Дальше{" "}
            <FontAwesomeIcon className="ml-2 fa-lg" icon={faArrowRight} />
          </Link>
        )}
      </div>
    </Layout>
  )
}
export default Telis

export const pageQuery = graphql`
  query TelisQuery($skip: Int!, $limit: Int!) {
    allContentfulBlog(
      sort: { fields: [createdAt], order: DESC }
      filter: {
        node_locale: { eq: "en-US" }
        category: { elemMatch: { title: { eq: "Телис" } } }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          slug
          title
          date
          createdAt
          category {
            title
            id
          }
          featuredImage {
            fluid(maxWidth: 1200, quality: 85) {
              src
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
